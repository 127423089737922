import axios from 'axios';
import ReactGA from 'react-ga4';

const sendEvent = async(method:string,) =>{
    // music.subox
    ReactGA.initialize('G-1ED18YR5PP');
    // subox.co
    // ReactGA.initialize('G-EE0WLCLCJZ');
    ReactGA.event({
        category: "web",
        action: 'main_call_api_web',
        label: method,
    })
}

// 애널리틱스할때
export const axiosConfigInstance = (path: string,token?:string,type?:any) =>{
    const instance = axios.create({
        baseURL: `https://music.dapi.subox.co.kr${path}`,
        // baseURL: `http://192.168.0.18:10125${path}`,
        timeout: 40000,
        headers: {
            'authorization':token,
            ...type
        }
    })
    instance.interceptors.request.use(
        async config => {
            await sendEvent(config.data.method)
          return config;
        },
        error => {
          return Promise.reject(error);
        }
    );
    return instance;
}

export const axiosHome = (path?: string,token?:string,type?:any) => axios.create({
    baseURL: `https://music.wapi.subox.co.kr${path === undefined ? '' : path}`,
    //  baseURL: `http://192.168.1.22:10700${path === undefined ? '' : path}`,
    timeout: 40000,
    headers: {
        'authorization':token,
        ...type
    }
});

export const axiosAI = (path:string,token?:string,type?:any) => axios.create({
    baseURL: `https://sound.green.atoreum.net${path}`,
    timeout: 40000,
    headers: {
        'authorization':token,
        ...type
    }
});

/*
export const IMG_URI = 'https://music.dapi.subox.co.kr';
// export const IMG_URI = 'http://192.168.1.21:10125';

export const HOME_IMG_URI = 'http://192.168.1.22:10700';
// export const HOME_IMG_URI = 'https://music.wapi.subox.co.kr';

export const PUBLIC_IMG = 'http://localhost:3000';
// export const PUBLIC_IMG = 'https://music.subox.co.kr';
// export const IMG_URI = ''
*/